import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Conversations from "./Conversations";
import ConversationDetails from "./ConversationDetails";


class App extends Component {
  render() {
    return (
      <div>
        <div className="container mt-3">
          <Routes>
            <Route path="/conversations/" element={<Conversations />} />
            <Route path="/conversations/:id" element={<ConversationDetails />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
